<template>
  <div id="app">
    <div class="bgimg">
      <div class="pageContent">
        <div class="head">
          <div class="title">大科智慧监测云平台</div>

          <div class="Htime">
            <div class="time">{{ hours }}</div>
            <div class="year">
              <div>{{ nowtime }}</div>

              <div>{{ week }}</div>
            </div>
          </div>
          <div class="exitpolice" style="pointer-events: auto; cursor: pointer">
            <div class="police">
              <img src="../assets/images/message.png" alt="" />
            </div>
            <div class="exit" @click="exit">
              <img src="../assets/images/exit.png" alt="" />
            </div>
          </div>
        </div>
        <div class="gongBtn">
          <!-- <div >设备总览</div> -->
          <div @click="goHome">监测中心</div>
        </div>
      </div>

      <!-- 左侧菜单块 -->
      <el-aside>
        <!-- 菜单 -->
        <el-menu
          unique-opened
          :default-active="activePath"
          class="el-menu-vertical-demo"
          background-color="#2C3B41"
          text-color="#fff"
          active-text-color="#68D8FE"
          router
          @open="handleOpen"
          @close="handleClose"
        >
          <div v-for="item in menulist" :key="item.id">
            <!-- 一级菜单的模板区域 -->
            <template
              v-if="
                (item.children && item.children.length == 0) || !item.children
              "
            >
              <el-menu-item
                :index="item.urlpath"
                @click="saveNavState(item.urlpath)"
              >
                <!-- <i :class="item.icon"></i> -->
                <span>{{ item.title }}</span>
              </el-menu-item>
            </template>
            <!-- 二级菜单 -->
            <el-submenu v-else :index="item.id + ''">
              <template slot="title">
                <!-- 图标 -->
                <!-- <i :class="item.icon"></i> -->
                <!-- 文本 -->
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item
                v-for="subItem in item.children"
                :key="subItem.id"
                :index="subItem.urlpath"
                @click="saveNavState(subItem.urlpath)"
              >
                <template slot="title">
                  <!-- 图标 -->
                  <!-- <i :class="subItem.icon"></i> -->
                  <!-- 文本 -->
                  <span>{{ subItem.title }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </div>
        </el-menu>
      </el-aside>

      <div class="lTop">
        <div class="lTopCon">
          <div class="lTopTitle">设备统计</div>

          <div class="beiChart">
            <div class="charttwo"></div>
            <div class="chartNum">
              <div class="numli">
                <div class="dian"></div>
                <div class="num">在线设备: {{ twoEquliston.value }}</div>
              </div>
              <div class="numli">
                <div class="dian"></div>
                <div class="num">离线设备: {{ twoEqulistoff.value }}</div>
              </div>
              <div class="numli">
                <div class="dian"></div>
                <div class="num">报警数量: {{ twoEqulistab.value }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="container" class="map"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
//  import "../config/anhui";
export default {
  data() {
    return {
      activeIndex: '1',
      indexs: 1,
      tableData: [],
      nowtime: '',
      hours: '',
      week: '',
      intnum: undefined,
      listTop: 0,
      shelist: [],
      policeList: [],
      dianList: [],
      itemarr: [],
      xdata: [],
      img: '',
      sheNum: [],
      dianlistdays: {},
      dianlistmonth: {},
      shebeiList: {},
      zuzhiList: [],
      policeList: [],
      barList: [],
      TMap: null,
      oneEqulistdev: {},
      oneEquliston: {},
      oneEqulistoff: {},
      twoEquliston: {},
      twoEqulistoff: {},
      twoEqulistab: {},
      map: null,
      geocoder: null,
      // 左侧菜单数据
      menulist: [],
      // 被激活的链接地址
      activePath: '',
    }
  },
  mounted: function () {
    this.activePath = window.sessionStorage.getItem('activePath')
    this.getMenuList()

    this.currentTime()

    // this.oneEqu();
    this.twoEqu()
    // this.threeShi();
    // this.fourPolice();
    // this.fivePolice();
    this.init()
  },

  methods: {
    async twoEqu() {
      const res = await this.$http.post('index/device_census', {
        device_type: '电缆',
      })
      if (res.code !== 1) return this.$message.error(res.meta.msg)
      this.twoEqulistab = res.data.abnormal_total
      this.twoEquliston = res.data.on_line
      this.twoEqulistoff = res.data.off_line
      this.twoChart(res.data)
    },
    twoChart(data) {
      const newdata = []
      newdata.push(data.on_line)
      newdata.push(data.off_line)
      newdata.push(data.abnormal_total)

      var myChart = echarts.init(document.querySelector('.charttwo'))
      var twoOption = {
        grid: {
          // top,bottom,left,right等
          left: '3%',
          right: '4%',
          top: '3%',
          bottom: '30%',
          // containLabel: true,
        },
        tooltip: {
          trigger: 'item',
          textStyle: {
            color: '#666',
          },
        },
        legend: {
          textStyle: {
            // 图例文字的样式
            color: '#fff',
            fontSize: 12,
          },
          // left: 80,
        },
        color: ['#397FFF', '#A5E0FF', '#39FFF8'],

        series: [
          {
            name: '电缆设备',
            type: 'pie',
            center: ['40%', '40%'],
            radius: ['40%', '50%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                color: '#fff',

                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: newdata,
          },
        ],
      }
      myChart.setOption(twoOption)
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    // 获取菜单
    async getMenuList() {
      const res = await this.$http.get('user/menu')
      if (res.code !== 1) return this.$message.error(res.meta.msg)
      this.menulist = res.data
      console.log(res, '1111')
    },
    // 保存链接的激活状态
    saveNavState(activePath) {
      console.log(activePath)
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },

    async init() {
      var map = new AMap.Map('container', {
        resizeEnable: true, // 是否监控地图容器尺寸变化
        mapStyle: 'amap://styles/grey',
        zoom: 8,
        center: [117.244901, 31.811592],
      })

      const res = await this.$http.post('index/device_list')

      if (res.code == 1) {
        const markersData = res.data.list
        markersData.forEach(function (markerInfo) {
          if (markerInfo.location && markerInfo.location.lng != null) {
            var marker = new AMap.Marker({
              icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
              position: [
                Number(markerInfo.location.lng),
                Number(markerInfo.location.lat),
              ],
              anchor: 'bottom-center',
            })
            map.add(marker) // 将标记添加到地图上
          }
        })
      }

      // var marker = new AMap.Marker({
      //   icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
      //   position: [107.675797, 36.214419],
      //   anchor: 'bottom-center'
      // });
      // map.add(marker)

      // 第二个标记
      // var marker2 = new AMap.Marker({
      //   icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
      //   position: [108.675797, 36.214419],
      //   anchor: 'bottom-center'
      // });
      // map.add(marker2);
      //     AMap.plugin(['AMap.ToolBar', 'AMap.Scale'], function () {
      //       map.addControl(new AMap.ToolBar())
      //       map.addControl(new AMap.Scale())
      //     })
      // map.on('click',this.getlnglat)
    },
    getlnglat(e) {
      const that = this
      console.log(e)
      const lng = e.lnglat.lng
      const lat = e.lnglat.lat
      var lnglatXY = [104.843766, 40.485657] // 地图上所标点的坐标
      AMap.plugin('AMap.Geocoder', function () {
        var geocoder = new AMap.Geocoder({
          radius: 1000,
          extensions: 'all',
        })
        geocoder.getAddress(lnglatXY, function (status, result) {
          console.log(status, result)
          if (status === 'complete' && result.info === 'OK') {
            var address = result.regeocode.formattedAddress
            that.address = address // 兑换地址
            alert(that.address)
            // that.$emit('getaddress', that.address, lnglatXY)
          } else {
            // ElMessage.error('无地址，请重新选择');
          }
        })
        // 清除所有标记
        // that.map.clearMap();
        // var marker = new AMap.Marker();
        // that.map.add(marker);
        // marker.setPosition(lnglatXY);
      })
    },

    // 时间获取
    currentTime() {
      setInterval(this.formatDate, 500)
    },
    formatDate() {
      const date = new Date()
      const year = date.getFullYear() // 年
      const month = date.getMonth() + 1 // 月
      const day = date.getDate() // 日
      const week = date.getDay() // 星期
      const weekArr = [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
      ]
      let hour = date.getHours() // 时
      hour = hour < 10 ? '0' + hour : hour // 如果只有一位，则前面补零
      let minute = date.getMinutes() // 分
      minute = minute < 10 ? '0' + minute : minute // 如果只有一位，则前面补零
      let second = date.getSeconds() // 秒
      second = second < 10 ? '0' + second : second // 如果只有一位，则前面补零
      this.nowtime = `${year}-${month}-${day}`
      this.hours = `${hour}:${minute}:${second}`
      if (week == 0) {
        this.week = '星期日'
      } else if (week == 1) {
        this.week = '星期一'
      } else if (week == 2) {
        this.week = '星期二'
      } else if (week == 3) {
        this.week = '星期三'
      } else if (week == 4) {
        this.week = '星期四'
      } else if (week == 5) {
        this.week = '星期五'
      } else if (week == 6) {
        this.week = '星期六'
      }
    },

    goHome() {
      // window.sessionStorage.setItem("activePath", "/monitor");
      // this.$router.push("/monitor");
      window.sessionStorage.setItem('activePath', '/cableList')
      this.$router.push('/cableList')
    },
    exit() {
      this.$router.push('/login')
    },
  },
}
</script>
<style lang="scss" scoped>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}
.lTop {
  position: absolute;
  right: 10px;
  pointer-events: none;
  z-index: 100;
  bottom: 0;
}
.map {
  position: relative;
  z-index: -1;
}
// 左侧菜单
.el-aside {
  pointer-events: all;
  width: 260px !important;
  height: 100%;
  position: absolute;
  z-index: 1000000;

  backdrop-filter: blur(10px) brightness(90%);
  background: rgba(255, 255, 255, 0.1);

  .el-menu {
    border: 0;
    text-align: left;
  }
}

// 修改点击选中后的样式
.el-submenu {
  // border-left: 6px solid #2b4490;
  box-sizing: border-box;
}
::v-deep .el-submenu__title {
  // background-color: #2b4490 !important;
  font-size: 16px;
  // border-top: 2px solid #2b4490;
}

::v-deep .el-menu-item {
  // background-color: #2b4490 !important;
  font-size: 16px;
  box-sizing: border-box;
  // border-left: 6px solid #2b4490;
}
::v-deep .el-menu-item:hover {
  color: #4486ff !important;
  border-color: #4486ff;
}
::v-deep .el-submenu__title i,
.el-menu-item i {
  color: #ffffff;
}
::v-deep .is-active.el-menu-item i {
  color: #4486ff;
}
::v-deep .is-active.el-menu-item {
  color: #4486ff !important;
}
::v-deep .el-menu-item:hover i {
  color: #4486ff;
}

// [v-cloak] {
//   display: none;
// }

/* html {
      overflow-y: scroll;
    } */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  width: 100%;
  height: 100%;
}

.bgimg {
  position: relative;
  /*  背景图定位 / 背景图尺寸  cover 完全铺满容器  contain 完整显示在容器内 */
  width: 100%;
  height: calc(100vh - 10px);
  // background: url('../assets/images/bg2.png') no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 100% 100%;
  /* 行高是字体1.15倍 */
  line-height: 1.15;
  // background-color: #030919;
  padding-top: 24px;
  z-index: 1000000;
  pointer-events: none;
}

.pageContent {
  position: relative;

  /* display: flex;
     flex-direction: column;
     align-items: center; */
}

.head {
  width: 100%;
  height: 68px;
  background: url('../assets/images/headBg.png') no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 100% 100%;
  /* 行高是字体1.15倍 */
  // line-height: 1.15;
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  align-items: center;
  // margin-bottom: 17px;
}

.title {
  font-family: HarmonyOS Sans SC;
  font-weight: bold;
  font-size: 45px;
  color: #4886ff;
  background: linear-gradient(0deg, #3b7dff 0%, #b1ebff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  // align-items: center;
  justify-content: center;
  // padding-top: 20px;
}
.Htime {
  position: absolute;
  top: 20px;
  left: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.time {
  height: 24px;
  font-family: HarmonyOS Sans SC;
  font-weight: bold;
  font-size: 26px;
  color: #4486ff;
  // border-right: 1px solid #4486FF;
  padding-right: 20px;
  margin-right: 50px;
}

.year {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: HarmonyOS Sans SC;
  font-weight: bold;
  font-size: 16px;
  color: #4486ff;
}
.exitpolice {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
}
.police {
  width: 26px;
  height: 28px;
}
.exit {
  width: 26px;
  height: 28px;
}
.gongBtn div {
  font-family: HarmonyOS Sans SC;
  font-weight: bold;
  font-size: 26px;
  color: #4486ff;
  text-align: center;
  pointer-events: all;
}
.gongBtn {
  position: absolute;
  top: 22px;
  right: 200px;
  font-family: HarmonyOS Sans SC;
  font-weight: bold;
  font-size: 26px;
  color: #4486ff;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
#container {
  // height: 1080px;
  height: calc(100vh + 80px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
// .map{
//   width: 500px;
//   height: 500px;
// }
.mainBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* min-width: 1024px; */
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 20px;
}

.column {
  /* flex: 1; */
}

.mainBox .column:nth-child(2) {
  /* flex: 2; */
  position: relative;
}
.bTop {
  // margin-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lTopTitle {
  font-family: HarmonyOS Sans SC;
  font-weight: bold;
  font-size: 22px;
  color: #bae5fb;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-left: 38px;
}
.beiChart {
  position: relative;
}
.chartNum {
  position: absolute;
  top: 50px;
  right: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .numli {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
  }
  .dian {
    width: 10px;
    height: 10px;
    background: #a5e0ff;
    border-radius: 50%;
    margin-right: 20px;
  }
  .num {
    font-family: HarmonyOS Sans SC;
    font-weight: 500;
    font-size: 20px;
    color: #a5e0ff;
  }
}
.lTopCon {
  width: 474px;
  height: 309px;
  background: url('../assets/images/kBg.png') no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 100% 100%;
  line-height: 1.15;
  padding-top: 14px;
  box-sizing: border-box;
  // padding: 60px;
  /* background-color: #030919; */
}

.lBotCon {
  width: 614px;
  height: 309px;

  background: url('../assets/images/botBg.png') no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 100% 100%;
  line-height: 1.15;
  margin-top: 17px;
  padding-top: 14px;
}
.chartone {
  margin-top: 20px;
  width: 460px;
  height: 300px;
}
.charttwo {
  margin-top: 20px;
  width: 460px;
  height: 300px;
}
.chartthree {
  margin-top: 10px;
  width: 600px;
  height: 280px;
  box-sizing: border-box;
}
.chartfour {
  margin-top: 10px;

  width: 600px;
  height: 280px;
}
.chartfive {
  margin-top: 10px;
  width: 440px;
  height: 280px;

  // width: 600px;
  // height: 280px;
}
.TOP {
  display: flex;
  align-items: center;
  /* margin-top: 44px; */
}
.sheAll {
  position: relative;
  width: 404px;
  height: 320px;
  background: url('../assets/images/shebeiAllBg.png') no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 100% 100%;
}
.sheallName {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 145px;
  left: 65px;
  line-height: 26px;
}
.sheallName div {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.sheallOne {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 29px;
  left: 272px;
  line-height: 22px;
}
.sheallTwo {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 140px;
  left: 340px;
  line-height: 22px;
}
.sheallThree {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 253px;
  left: 272px;
  line-height: 22px;
}
.aa div {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #34abff;
}
.rTopL {
  width: 756px;
  height: 428px;
  background: url('../assets/images/zsBg.png') no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 100% 100%;
  margin: 0 20px;
  margin-top: 48px;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
}
.rTopR {
}

.lTopConBot {
  width: 1333px;
  height: 444px;
  background: url('../assets/images/zxBg.png') no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 100% 100%;
  padding: 0 60px;
}
</style>
